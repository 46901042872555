import React, { FC, useEffect, useState } from 'react'

import { FormikProps } from 'formik'
import {
    Text,
    Box,
    FormControl,
    FormLabel,
    Select,
    Spinner,
    Input,
    useToast,
    useRadioGroup,
    HStack,
    useRadio,
} from '@chakra-ui/react'

import { Distributor, RepresentativeType, Solution } from '@/interfaces'
import { getPackageManagment, getProductManagment } from '@/hooks/solutions'
import { EProductManagment, ESolutionManagment } from '@/constants/solutions'
import { HAS_LAPTOP_OPTIONS, HAS_PRINTER_OPTIONS } from '@/constants/client'
import { colors } from '@/utils/const'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
    solutions: Solution[]
    opportunityDistributor: Distributor
    segment: number
    forceShowLaptop: boolean
}

interface FormikValues {
    representatives: Representative[]
    products: ProductsFormik[]
    has_printer: string
    has_laptop: string
    client_type: string
}

interface Representative {
    representative_position: string
}

interface ProductsFormik {
    solution_managment_id: string
    product_managment_id: string
    package_managment_id: string
    price: string
}

export const Step6: FC<Props> = ({
    formik,
    representativesTypes,
    opportunityDistributor,
    solutions,
    segment,
}) => {
    const [isLoadingProduct, setIsLoadingProduct] = useState(false)
    const [solutionsSelect, setSolutionsSelect] = useState([
        {
            solutions: [],
            products: [],
            packages: [],
        },
    ])
    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue: formik.values.has_printer,
        onChange: value => {
            formik.setFieldValue('has_printer', value)
        },
    })
    const {
        getRootProps: getLaptopRootProps,
        getRadioProps: getLaptopRadioProps,
    } = useRadioGroup({
        defaultValue: formik.values.has_laptop,
        onChange: value => {
            formik.setFieldValue('has_laptop', value)
        },
    })

    const toast = useToast()

    useEffect(() => {
        const setInitialValues = async () => {
            try {
                setIsLoadingProduct(true)
                const productsManagment = await getProductManagment({
                    solution_managment_id:
                        ESolutionManagment.proccess_managment,
                })

                const packageManagment = await getPackageManagment({
                    product_managment_id: EProductManagment.flesip,
                })

                formik.setFieldValue(
                    'products[0].solution_managment_id',
                    ESolutionManagment.proccess_managment,
                )

                formik.setFieldValue(
                    'products[0].product_managment_id',
                    EProductManagment.flesip,
                )

                if (opportunityDistributor?.profile?.package) {
                    const { id, price } = packageManagment.find(
                        ({ id }) =>
                            id ===
                            Number(opportunityDistributor?.profile?.package),
                    )

                    formik.setFieldValue('products[0].package_managment_id', id)
                    formik.setFieldValue('products[0].price', price)
                }

                setSolutionsSelect([
                    {
                        solutions,
                        products: productsManagment,
                        packages: packageManagment,
                    },
                ])
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            } finally {
                setIsLoadingProduct(false)
            }
        }

        setInitialValues()
    }, [])

    const simpleChangePackage = async (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const packageManagmentId = parseInt(e.target.value)
        if (isNaN(packageManagmentId)) return null

        const [{ packages }] = solutionsSelect
        const { id, price } = packages.find(
            ({ id }) => id === packageManagmentId,
        )

        formik.setFieldValue('products[0].package_managment_id', id)
        formik.setFieldValue('products[0].price', price)
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>

            {isLoadingProduct ? (
                <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    h="300px"
                    w="full">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                </Box>
            ) : (
                <Box mt={4} w="90%" color="black">
                    <FormControl mt={4}>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Solución
                        </FormLabel>

                        <Select
                            id={`products[${0}].solution_managment_id`}
                            placeholder="Solución"
                            color="#4F4F4F"
                            value={
                                formik.values.products[0]?.solution_managment_id
                            }
                            fontSize={13}
                            rounded="md"
                            isDisabled
                            size="xs">
                            {solutionsSelect[0]?.solutions.map(
                                (item, index) => {
                                    if (!item.is_contable) return null

                                    return (
                                        <option key={index} value={item.id}>
                                            {item.label}
                                        </option>
                                    )
                                },
                            )}
                        </Select>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Producto
                        </FormLabel>

                        <Select
                            id={`products[${0}].product_managment_id`}
                            placeholder="Producto"
                            color="#4F4F4F"
                            isDisabled
                            value={
                                formik.values.products[0]?.product_managment_id
                            }
                            fontSize={13}
                            rounded="md"
                            size="xs">
                            {solutionsSelect[0]?.products.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mt={4} isRequired>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Paquete
                        </FormLabel>

                        <Select
                            id={`products[${0}].package_managment_id`}
                            color="#4F4F4F"
                            placeholder="Paquete"
                            isDisabled={
                                solutionsSelect[0]?.packages.length === 0
                            }
                            value={
                                formik.values.products[0]?.package_managment_id
                            }
                            fontSize={13}
                            onChange={simpleChangePackage}
                            rounded="md"
                            size="xs">
                            {solutionsSelect[0]?.packages.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Precio
                        </FormLabel>

                        <Input
                            id={`products[${0}].price`}
                            placeholder="Precio"
                            isDisabled
                            color="#4F4F4F"
                            value={formik.values.products[0]?.price}
                            fontSize={13}
                            onChange={formik.handleChange}
                            rounded="md"
                            size="xs"
                        />
                    </FormControl>

                    <FormControl
                        mt={4}
                        hidden={
                            typeof segment !== 'undefined' && segment !== 3
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            ¿Quiere solicitar el ordenador?
                        </FormLabel>

                        <HStack {...getLaptopRootProps()}>
                            {Object.values(HAS_LAPTOP_OPTIONS).map(item => {
                                const radio = getLaptopRadioProps({
                                    value: item.value,
                                })
                                return (
                                    <RadioCard
                                        key={item.value}
                                        value={item.value}
                                        {...radio}>
                                        {item.label}
                                    </RadioCard>
                                )
                            })}
                        </HStack>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel fontSize={13} fontWeight={700}>
                            ¿Se debe entregar impresora?
                        </FormLabel>

                        <HStack {...getRootProps()}>
                            {Object.values(HAS_PRINTER_OPTIONS).map(item => {
                                const radio = getRadioProps({
                                    value: item.value,
                                })
                                return (
                                    <RadioCard
                                        key={item.value}
                                        value={item.value}
                                        {...radio}>
                                        {item.label}
                                    </RadioCard>
                                )
                            })}
                        </HStack>
                    </FormControl>
                </Box>
            )}
        </Box>
    )
}

const RadioCard = props => {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                bg="white"
                color={colors['data-text-principal']}
                borderWidth="1px"
                borderColor={colors['data-text-principal']}
                borderRadius="md"
                textAlign="center"
                fontWeight={600}
                _checked={{
                    bg: colors['data-button-principal'],
                    color: 'white',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={6}
                py={2}>
                {props.children}
            </Box>
        </Box>
    )
}
